import React from "react"
import { Link, graphql } from "gatsby"
import MyGallery from "../components/main/gallery"
import Header from "../components/header/header"
import Footer from "../components/footer"
import slugify from "slugify"
import { FaAngleLeft, FaRegEnvelope } from "react-icons/fa"

const ServiceDetail = ({ data: { markdownRemark, allFile }, location }) => {
  const serivePath = location.state
    ? slugify(location.state?.service.toLowerCase())
    : null
  const path = location
    ? location.pathname.split("service/", 1) + "service/" + serivePath
    : null
  return (
    <div>
      <Header />
      <div className="bg-white">
        <div className="pt-6 pb-6 max-w-4xl mx-auto">
          {path && (
            <Link to={path}>
              <div className="flex items-center pb-4 pl-2">
                <FaAngleLeft />
                <span className="w-32 font-semibold"> précédent</span>
              </div>
            </Link>
          )}
          <MyGallery
            allFile={allFile.edges}
            imgSrc={markdownRemark.frontmatter.photos}
          />
          <div className="pl-2 pr-2">
            <div className="pt-4 pb-4 flex justify-between items-center">
              <h1 className="font-bold text-base md:text-2xl tracking-tight uppercase">
                {markdownRemark.frontmatter.title}
              </h1>
              <div>
                <Link
                  to="/contact"
                  className="w-fit shadow-md rounded-full bg-blue-900 text-slate-100 pb-2 pt-2 pr-4 pl-4 text-center  font-semibold flex items-center"
                >
                  <FaRegEnvelope className="text-sm md:text-base" />
                  <span className="pl-4 text-sm md:text-base">contact</span>
                </Link>
              </div>
            </div>
            <div className=" border border-b-islate-300 mb-4"></div>
            <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        photos
      }
    }
    allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }) {
      edges {
        node {
          publicURL
          name
          id
          childImageSharp {
            original {
              height
              width
            }
          }
        }
      }
    }
  }
`

export default ServiceDetail
