import React, { useRef } from "react"
import classNames from "classnames"
import { CustomGallery, Item, DefaultLayout } from "react-photoswipe-gallery"
import PhotoswipeUIDefault from "photoswipe/dist/photoswipe-ui-default"
import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

const MyGallery = ({ allFile, imgSrc }) => {
  const layoutRef = useRef()
  const defaultImage = [
    {
      node: {
        publicURL: "/static/0ea52ac090e73acf9f0c5a391d1f98a1/placeholder.png",
        name: "placeholder",
        childImageSharp: {
          original: {
            height: 960,
            width: 1280,
          },
        },
        id: "b5171ed6-e6af-5092-81b0-fc6441487826",
      },
    },
  ]
  const filteredGalleryImages = allFile.filter(e =>
    imgSrc.find(obj => obj === e.node.name)
  )
  const controlDisplayedImages =
    filteredGalleryImages &&
    filteredGalleryImages.map((f, index) => {
      let obj = {
        node: {
          childImageSharp: {
            original: {
              height: f.node.childImageSharp.original.height,
              width: f.node.childImageSharp.original.width,
            },
          },
          id: f.node.id,
          name: f.node.name,
          publicURL: f.node.publicURL,
        },
      }
      if (index > 2) {
        obj.node.displayNone = true
      }
      if (index > 1) {
        obj.node.resizeDisplayNone = true
      }
      if (index > 0) {
        obj.node.imgMiniatured = true
      }
      return obj
    })
  const galleryImages =
    filteredGalleryImages.length !== 0 ? controlDisplayedImages : defaultImage

  return (
    <div
      className={classNames(
        galleryImages.length <= 2
          ? "relative grid gap-4 sm:mb-6 sm:grid-cols-4 md:gap-4 md:grid-rows-1 md:grid-flow-col md:mb-0"
          : "relative grid gap-4 sm:mb-6 sm:grid-cols-4 md:gap-4 md:grid-rows-2 md:grid-flow-col md:mb-0"
      )}
    >
      <CustomGallery
        layoutRef={layoutRef}
        ui={PhotoswipeUIDefault}
        options={{ bgOpacity: 0.8, getThumbBoundsFn: undefined }}
      >
        {galleryImages?.map((f, index) => {
          var btnClassLeft = classNames({
            "drop-shadow-lg w-full h-[320px] object-cover rounded-lg sm:h-[380px] md:h-[525px] sm:col-span-2 md:row-span-3": true,
            "sm:col-span-4":
              f.node.name === "placeholder" || galleryImages.length <= 1,
            "sm:col-span-2": f.node.name !== "placeholder",
          })
          var btnClassRight = classNames({
            "drop-shadow-lg hidden w-full h-[320px] sm:h-[380px] object-cover rounded-lg sm:raw-span-2 sm:col-span-2 md:block md:h-[254px] md:col-span-2": true,
            "sm:hidden": index > 1,
            "sm:block": index <= 1,
            "md:h-[525px]": galleryImages.length <= 2,
          })

          return (
            <React.Fragment key={f.node.id}>
              <Item
                original={f.node.publicURL}
                thumbnail={f.node.publicURL}
                width={f.node.childImageSharp.original.width}
                height={f.node.childImageSharp.original.height}
              >
                {({ ref, open }) => (
                  <React.Fragment>
                    <img
                      ref={ref}
                      onClick={
                        defaultImage[0].node.publicURL !== f.node.publicURL
                          ? open
                          : null
                      }
                      alt="tata"
                      className={
                        !f.node.imgMiniatured ? btnClassLeft : btnClassRight
                      }
                      src={f.node.publicURL}
                      style={{ display: f.node.displayNone ? "none" : "" }}
                    />
                    {index === 0 && f.node.name !== "placeholder" && (
                      <span
                        className="absolute z-10 bottom-10 right-10 rounded-full bg-white text-sm text-back px-4 py-2"
                        ref={ref}
                        onClick={
                          defaultImage[0].node.publicURL !== f.node.publicURL
                            ? open
                            : null
                        }
                      >
                        voir les {galleryImages.length} photo
                        {galleryImages.length !== 1 ? "s" : ""}
                      </span>
                    )}
                  </React.Fragment>
                )}
              </Item>
            </React.Fragment>
          )
        })}
      </CustomGallery>
      <DefaultLayout
        shareButton={false}
        fullscreenButton={false}
        zoomButton={false}
        ref={layoutRef}
      />
    </div>
  )
}

export default MyGallery
